body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
.header {
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 20px 20px;
  color: #eb6223;
  border-radius: 3px 3px 0 0;
}

.h2 {
  font-weight: 400;
}

.logoheader {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.5s ease all;

  background-image: url("../public/logo192.png");
  background-color: #fff;
  background-size: 40px 40px;
} */