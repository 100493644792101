.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

h2 {
  font-weight: 400;
  color: #eb6223!important;
}

.hideform {
  display: none;
}

.text2display {
  margin-bottom: 5%;
  font-size: larger;
  font-weight: bold;

  word-spacing: 0.4em;
  letter-spacing: .1em;
  line-height: 200%;
  border-left-color: #eb6223;
  border-left-style: solid;
  padding-left: 5%;

}

.consigne {
  margin-bottom: 5%;
  padding-left: 5%;
  line-height: 200%;
}

.ant-layout-footer {
  padding-left: 5%!important;
  padding-right: 5%!important;
}

#formpart3 {
  text-align: center;
}

#formpart1, #formpart2 {
  margin-left: 5%;
  margin-right: 5%;
}

#formpart2 {
  background-color: #f0f2f5;
}

.logoheader {
  display: inline-block;
  margin-top: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.5s ease all;

  background-image: url("../public/logo192.png");
  background-color: #fff;
  background-size: 40px 40px;
}

.commandes {
  text-align: center;
}